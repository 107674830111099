import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";

// Assets

const CareersFirmwareEngineer = () => (
  <Layout currentPage="careers-listing">
    <div className="container">
      <Link to="/careers#alljobs">
        <p className="backbutton col-md-12">Back to Careers</p>
      </Link>
      <h1 className="jobtitle col-md-12">Firmware Engineer</h1>
      <div className="jobdescription col-md-12"> </div>
      <div className="row col-md-12 justify-content-md-between">
        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You will:
          </div>
        </div>
        <div className="col-md-9">
          <ul className="aligned-left jobsection">
            <li>
              Write low level device drivers, do board bring and deliver high
              quality, robust firmware to production. Write low level peripheral
              drivers needed for board and application bring up.
            </li>
            <li>
              Participate in firmware design, development, verification,
              troubleshooting, and delivery of high quality firmware; lead and
              participate in technical discussions across engineering and
              product teams.
            </li>
            <li>
              Work closely with the digital and physical product teams for
              definition and implementation of wireframes used by devices for
              wireless communications.
            </li>
            <li>Build make files and release creator scripts as needed.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">You are:</div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>Proficient in creating reliable, efficient low level code.</li>
            <li>
              Able to document design, functional specifications and releases
              documentation.
            </li>
            <li>Obsessed with optimization.</li>
            <li>Through in your thinking, investigation and implementation.</li>
            <li>Rigorous with your syntax and making it clear to others.</li>
            <li>
              Very comfortable reading hardware schematics and debugging
              hardware at a board level.
            </li>
            <li>Able to work with backend and frontend developers.</li>
            <li>
              Diligent in documentation of design, functional specifications and
              releases documentation.
            </li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You bring:
          </div>
        </div>
        <div className="col-md-9 ">
          <ul className="aligned-left jobsection">
            <li>BS/MS/PhD. in Electrical Engineering or Computer Science.</li>
            <li>
              5+ years of developing production firmware for 32/64 bit
              microcontrollers.
            </li>
            <li>
              Familiarity with contactless hardware and protocols (RFID, NFC,
              etc.).
            </li>
            <li>
              Familiarity/Experience working with network stacks and protocols
              TCP/IP, UDP, MQTT etc.
            </li>
            <li>
              Experience writing device drivers for serial communication
              protocols such as SPI/I2C/RS-232/USB etc.
            </li>
            <li>
              Extensive experience with production level firmware for power &
              memory constrained embedded products.
            </li>
            <li>
              Deep knowledge of electrical concepts and Strong knowledge of C
              and any one scripting language—preferably Python.
            </li>
            <li>
              Working knowledge of commonly used IDEs and version tools such as
              git.
            </li>
            <li>
              Strong knowledge and experience with a RTOS used in small
              footprint embedded hardware.
            </li>
            <li>
              Experience working with compliance and labs for device
              certification (FCC/CE/ETSI etc) a plus.
            </li>
            <li>Experience working with wireless IoT devices is a plus.</li>
          </ul>
        </div>

        <div className="col-md-3">
          <div className="aligned-left bold-text jobsectiontitle">
            You apply:
          </div>
        </div>
        <div className="col-md-9">
          <div className="aligned-left" style={{ paddingBottom: "100px" }}>
            By sending us an <a href="mailto:careers@introducing.uno">email</a>{" "}
            with your resume and a cover letter.
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default CareersFirmwareEngineer;
